import 'styles/globals.css';
import { useEffect, type ReactElement, type ReactNode, useState } from 'react';
import { MDXProvider } from '@mdx-js/react';
import type { AppProps } from 'next/app';
import type { NextPage } from 'next';
import PrimaryLayout from '@/components/Layout/PrimaryLayout';
import LandingLayout from '@/components/Layout/LandingLayout';
import { DefaultSeo } from 'next-seo';
import { DrawerProvider } from '@/components/Drawer';
import LeadFormDrawer from '@/components/Drawer/LeadFormDrawer';
import GoogleTagManger from '@/components/Script/GoogleTagManager';
import PostLayout from '@/components/Post/PostLayout';
import PostH1 from '@/components/Post/PostH1';
import { H3, H4, H5 } from '@/components/Element/Heading';
import SmartLink from '@/components/Button/SmartLink';
import ExitIntentPopupV2 from '@/components/Popup/ExitPopupV2';
import ExitIntentPopup from '@/components/Popup/ExitPopup';
import { useRouter } from 'next/router';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const components = {
  h1: PostH1,
  h2: H3,
  h3: H4,
  h4: H5,
  a: SmartLink,
  wrapper: ({ ...rest }) => <PostLayout {...rest} />,
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const noPopup = [
    '/thanks',
    '/become-a-partner',
    '/figure',
    '/quote',
    '/lead-widget',
  ].includes(router.pathname);
  const [showPopupV2, setShowPopupV2] = useState(false);
  // vwo split test code
  useEffect(() => {
    function checkVwoLoaded() {
      if (window._vwo_code && window._vwo_code.getVariationName) {
        const variationName = window._vwo_code.getVariationName(
          window._vwo_code.currentCampaign
        );
        if (variationName === 'ExitIntentPopupV2') {
          setShowPopupV2(true);
        }
      } else {
        // Retry after a delay if VWO script is not loaded yet
        setTimeout(checkVwoLoaded, 100);
      }
    }

    if (!noPopup) {
      checkVwoLoaded();
    }
  }, [noPopup]);

  // Use the layout defined at the page level, if available
  const getLayout =
    Component.getLayout ??
    ((page) => {
      if (
        router.pathname === '/figure' ||
        router.pathname === '/become-a-partner' ||
        router.pathname === '/promo' ||
        router.pathname === '/getstarted/sunnova' ||
        router.pathname === '/shop' ||
        router.pathname === '/a' ||
        router.pathname === '/c' ||
        router.pathname === '/lead-widget'
      ) {
        return <LandingLayout>{page}</LandingLayout>;
      } else {
        return <PrimaryLayout>{page}</PrimaryLayout>;
      }
    });
  // const getLayout =
  //   Component.getLayout ?? ((page) => <PrimaryLayout>{page}</PrimaryLayout>);

  return (
    <MDXProvider components={{ ...components, ...pageProps }}>
      <GoogleTagManger key="gtm" />
      <DefaultSeo
        defaultTitle="EnergyPal - Install Solar Panel &amp; Home Battery Systems for Less"
        description="EnergyPal helps you go solar for less. Find great deals on the best solar panel &amp; home battery systems. Compare costs &amp; install with ease."
        openGraph={{
          type: 'website',
          locale: 'en_US',
          url: 'https://energypal.com',
          site_name: 'EnergyPal',
          title: 'EnergyPal',
          images: [
            {
              url: 'https://energypal.com/banners/buy-sunpower-solar-panels-and-batteries-for-less-cost.jpg',
              type: 'image/jpeg',
              width: 2160,
              height: 947,
              alt: 'EnergyPal - Buy SunPower solar panels and batteries for less cost',
            },
          ],
        }}
      />
      {/* <Head>
        <meta
          name="image"
          property="og:image"
          content="https://energypal.com/bannersbuy-sunpower-solar-panels-and-batteries-for-less-cost.jpg"
        />
      </Head> */}
      <DrawerProvider>
        {getLayout(<Component {...pageProps} />)}
        <LeadFormDrawer />
        {/* Popup split test */}
        {!noPopup &&
          (showPopupV2 ? <ExitIntentPopupV2 /> : <ExitIntentPopup />)}
      </DrawerProvider>
    </MDXProvider>
  );
}

export default MyApp;
